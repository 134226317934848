import _websocket from "./lib/websocket";
import _stream from "./lib/stream";
import _websocketServer from "./lib/websocket-server";
import _receiver from "./lib/receiver";
import _sender from "./lib/sender";
var exports = {};
const WebSocket = _websocket;
WebSocket.createWebSocketStream = _stream;
WebSocket.Server = _websocketServer;
WebSocket.Receiver = _receiver;
WebSocket.Sender = _sender;
WebSocket.WebSocket = WebSocket;
WebSocket.WebSocketServer = WebSocket.Server;
exports = WebSocket;
export default exports;